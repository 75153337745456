import React from "react";
import { useState, useEffect } from "react";

/*
|--------------------------------------------------------------------------
| A Main Header component
|--------------------------------------------------------------------------

| This value is the name of your application, which will be used when the
| framework needs to place the application's name in a notification or
| other UI elements where an application name needs to be displayed.
|
*/
export default function Headers({ string }) {
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");

  async function sayHello() {
    const data = await fetch("https://dummyjson.com/quotes/random");
    const quote = await data.json();
    setQuote(quote.quote);
    setAuthor(quote.author);
  }

  useEffect(() => {
    // This will only run once when the component mounts
    sayHello();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <figure class="text-center">
          <blockquote class="blockquote">
            <h2>{quote}</h2>
          </blockquote>
          <figcaption class="blockquote-footer">
            <cite title={author}>{author}</cite>
          </figcaption>
          <button className="btn btn-success btn-sm" onClick={sayHello}>
            Generate Quote
          </button>
        </figure>
        <br />
      </div>
    </>
  );
}
